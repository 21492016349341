<template>
  <div class="TeacherDetail">
    <div class="pos-ab" id="tea-pos-ab">
      <div class="nav-container">
        <div class="crumbs">首页 > 讲师库 > 个人简介</div>
        <div class="details">
          <img
            :src="rankImg[teacherDeatil.rank]"
            alt=""
            class="mb-16 rankImg"
          />
          <div class="flex-alc">
            <div class="teacher-left">
              <img
                :src="
                  teacherDeatil.avatar || require('@/assets/defulatAvatar.png')
                "
                alt=""
                class="head"
              />
              <div class="flex-alc mb-15">
                <div class="fraction">
                  {{ Number(teacherDeatil.star).toFixed(1) }}
                </div>
                <el-rate
                  :value="teacherDeatil.star / 2"
                  disabled
                  text-color="#FF7500"
                  :max="5"
                >
                </el-rate>
              </div>
              <div class="name">{{ teacherDeatil.name }}</div>
            </div>
            <div class="teacher-right">
              <div class="brief">个人简介</div>
              <div class="desc one-line five">
                {{ teacherDeatil.intro }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="seat"></div>

    <div class="curriculum">
      <div class="title">TA的课程</div>
      <div class="flex wrap wid_100">
        <Curriculum
          v-for="(item, index) in teacherCur"
          :key="'cur' + index"
          class="mb-20"
          :curriculum="item"
          :imgSize="218"
          :border="6"
          :class="{ 'mr-27': (index + 1) % 5 != 0 }"
        ></Curriculum>
      </div>
    </div>
    <div class="flex-jsc mt-47 mb-63">
      <Pagination
        :pageSize="15"
        :total="total"
        :page="page"
        @getPage="getPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import Curriculum from "@/components/Curriculum.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "TeacherDetail",
  components: {
    Curriculum,
    Pagination,
  },
  data() {
    return {
      value: 5,
      teacherDeatil: "", //讲师详情
      teacherCur: [], //讲师课程列表
      total: 0, //总数据数
      rankImg: [
        require("@/assets/one.png"),
        require("@/assets/two.png"),
        require("@/assets/three.png"),
        require("@/assets/three.png"),
        require("@/assets/three.png"),
        // require("@/assets/ic_qt@2x.png"),
        // require("@/assets/ic_by@2x.png"),
        // require("@/assets/ic_hj@2x.png"),
        // require("@/assets/ic_zs@2x.png"),
        // require("@/assets/ic_wp@2x.png"),
      ],
      page: 1,
    };
  },

  async mounted() {
    //讲师详情
    await this.$https
      .get("/api/lecturer/detail", { id: this.$route.query.id })
      .then((res) => {
        this.teacherDeatil = res.data.detail;
      });
    await this.getTeacherCur();
    //设置占位高度
    this.$nextTick(() => {
      let navheight = document.getElementById("tea-pos-ab").offsetHeight;
      document.getElementById("seat").style.height = navheight + "px";
    });
  },

  methods: {
    //分页
    getPage(e) {
      this.getTeacherCur(e);
      this.page = e;
    },
    //讲师课程
    getTeacherCur(page = 1, type = 0) {
      this.$https
        .get("/api/cur/list", {
          lecturer: this.$route.query.id,
          limit: 15,
          page,
        })
        .then((res) => {
          if (type) {
            this.teacherCur = [
              ...this.teacherCur,
              ...res.data.list,
            ];
          } else {
            this.teacherCur = res.data.list;
          }
          this.total = res.data.total_count;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.TeacherDetail {
  width: 1200px;
  margin: auto;
  .pos-ab {
    position: absolute;
    width: 100%;
    background: white;
    left: 0;
    .nav-container {
      width: 1200px;
      margin: auto;
      padding-bottom: 30px;
      background: white;
      .crumbs {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        width: 100%;
        text-align: end;
      }
      .details {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .rankImg {
          width: 77px;
          height: 25px;
        }
        .teacher-left {
          margin-right: 124px;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-left: 50px;
          .head {
            width: 110px;
            height: 110px;
            border-radius: 100%;
            margin-bottom: 23px;
          }
          .fraction {
            font-size: 14px;
            color: #ff7500;
            margin-right: 10px;
          }
          .name {
            font-size: 20px;
            font-weight: 500;
            color: #000000;
          }
          /deep/.el-rate__icon {
            font-size: 14px;
            margin-right: 0px;
          }
        }
        .teacher-right {
          min-width: 817px;
          max-width: 820px;
          .brief {
            font-size: 20px;
            font-weight: 400;
            color: #000000;
            margin-bottom: 12px;
          }
          .desc {
            font-size: 14px;
            font-weight: 400;
            color: #a8a8a8;
          }
          .five {
            -webkit-line-clamp: 5;
          }
        }
      }
    }
  }
  .curriculum {
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }
}
</style>